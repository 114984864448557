import React, { useEffect } from "react";
import ReactGA from "react-ga";
// import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import constants from "./shared/constants";
import * as scenes from "./scenes";
import "./styles.css";

function App() {
  // Initialize Google Analytics
  useEffect(() => {
    ReactGA.initialize(constants.GoogleAnalyticsTrackingId);
  }, []);

  return (
    <Router>
      <Routes>
        {/* Redirect G2W */}
        <Route exact path="/g2w" element={<scenes.actionScenes.G2W />} />
        {/* Exportar Tabelas */}
        <Route
          exact
          path="/exportar_tabelas"
          element={<scenes.actionScenes.ExportTable />}
        />
        {/* Login */}
        <Route exact path="/" element={<scenes.actionScenes.Login />} />
        {/* Homes */}
        <Route exact path="/:accessType" element={<scenes.Home />} />
        {/* Serviços Online */}
        <Route
          exact
          path="/:accessType/servicos-online"
          element={<scenes.listScenes.onlineServicesMenus.MainMenu />}
        />
        <Route
          path="/:accessType/servicos-online/atualizacao_cadastral"
          element={<scenes.formScenes.UserRegistrationUpdate />}
        />
        <Route
          path="/:accessType/projeto_encontro"
          element={<scenes.formScenes.EncontroProject />}
        />
        <Route
          exact
          path="/:accessType/servicos-online/orientacoes-profissionais"
          element={
            <scenes.listScenes.onlineServicesMenus.ProfessinalOrientations />
          }
        />
        <Route
          path="/:accessType/servicos-online/orientacoes-profissionais/:professionalOrientationID"
          element={
            <scenes.MenuLinksScenes.onlineServicesScenes.ProfessionalOrientationsScene />
          }
        />
        <Route
          exact
          path="/:accessType/servicos-online/orientacoes-empresas-e-consultorios"
          element={
            <scenes.listScenes.onlineServicesMenus.EstablishmentOrientations />
          }
        />
        <Route
          path="/:accessType/servicos-online/orientacoes-empresas-e-consultorios/:establishmentOrientationID"
          element={
            <scenes.MenuLinksScenes.onlineServicesScenes.EstablishmentOrientationsScene />
          }
        />
        <Route
          exact
          path="/:accessType/servicos-online/orientacoes-estagios"
          element={
            <scenes.listScenes.onlineServicesMenus.InternshipOrientations />
          }
        />
        <Route
          path="/:accessType/servicos-online/orientacoes-estagios/:internshipOrientationID"
          element={
            <scenes.MenuLinksScenes.onlineServicesScenes.InternshipOrientationsScene />
          }
        />
        <Route
          path="/:accessType/servicos-online/termo-de-compromisso-estagio"
          element={
            <scenes.MenuLinksScenes.onlineServicesScenes.InternshipContract />
          }
        />
        <Route
          path="/:accessType/servicos-online/manual_de_autofiscalizacao"
          element={
            <scenes.MenuLinksScenes.onlineServicesScenes.SupervisionManual />
          }
        />
        {/* Publications */}
        <Route
          path="/:accessType/noticias"
          element={<scenes.listScenes.Publications />}
        />
        <Route
          path="/:accessType/comunicados"
          element={<scenes.listScenes.Publications />}
        />
        {/* Show Content */}
        <Route
          path="/:accessType/conteudo/:contentID"
          element={<scenes.ShowContent />}
        />
        {/* Menu Crefito2 */}
        <Route
          exact
          path="/:accessType/crefito2"
          element={
            <scenes.listScenes.Menu
              title="Crefito-2"
              links={scenes.MenuLinksScenes.crefito2MenuInfo.links}
              dir={[]}
            />
          }
        />
        <Route
          path="/:accessType/crefito2/missao_visao_e_valores"
          element={
            <scenes.MenuLinksScenes.crefito2Scenes.MissionVisionAndValues />
          }
        />
        <Route
          path="/:accessType/crefito2/atribuicoes"
          element={<scenes.MenuLinksScenes.crefito2Scenes.Attributions />}
        />
        <Route
          path="/:accessType/crefito2/gestao"
          element={<scenes.MenuLinksScenes.crefito2Scenes.Board />}
        />
        <Route
          path="/:accessType/crefito2/polos_de_representacao_institucional"
          element={
            <scenes.MenuLinksScenes.crefito2Scenes.InstitutionalCenters />
          }
        />
        <Route
          path="/:accessType/crefito2/assessorias_e_departamentos"
          element={
            <scenes.MenuLinksScenes.crefito2Scenes.AdvisoryDepartments />
          }
        />
        <Route
          path="/:accessType/crefito2/sistema_coffito-crefitos"
          element={<scenes.MenuLinksScenes.crefito2Scenes.Systems />}
        />
        <Route
          path="/:accessType/crefito2/regimento_interno"
          element={<scenes.MenuLinksScenes.crefito2Scenes.Regiment />}
        />
        {/* Menu Fisioterapia */}
        <Route
          exact
          path="/:accessType/fisioterapia"
          element={
            <scenes.listScenes.MenuFromAPI
              title="Fisioterapia"
              apiRoute="/fisioterapia"
            />
          }
        />
        <Route
          path="/:accessType/fisioterapia/:physiotherapyID"
          element={<scenes.MenuLinksScenes.PhysiotherapyScene />}
        />
        {/* Menu Terapia Ocupacional */}
        <Route
          exact
          path="/:accessType/terapia-ocupacional"
          element={
            <scenes.listScenes.MenuFromAPI
              title="Terapia Ocupacional"
              apiRoute="/terapia-ocupacional"
            />
          }
        />
        <Route
          path="/:accessType/terapia-ocupacional/:ocupationalTherapyID"
          element={<scenes.MenuLinksScenes.OcupationalTherapyScene />}
        />
        {/* Transparência */}
        <Route
          exact
          path="/:accessType/transparencia"
          element={<scenes.MenuLinksScenes.Transparency />}
        />
        {/* Licitações */}
        <Route
          exact
          path="/:accessType/licitacoes"
          element={<scenes.specialListScenes.Bids />}
        />
        <Route
          path="/:accessType/licitacoes/:bidID"
          element={<scenes.MenuLinksScenes.BidScene />}
        />
        {/* Capacitações e Cursos */}
        <Route
          exact
          path="/:accessType/capacitacoes-e-cursos"
          element={<scenes.specialListScenes.Courses />}
        />
        <Route
          path="/:accessType/capacitacoes-e-cursos/:courseID"
          element={<scenes.MenuLinksScenes.CourseScene />}
        />
        {/* Concursos e Oportunidades */}
        <Route
          exact
          path="/:accessType/concursos_e_oportunidades"
          element={<scenes.specialListScenes.Contests />}
        />
        <Route
          path="/:accessType/concursos_e_oportunidades/:contestID"
          element={<scenes.MenuLinksScenes.ContestScene />}
        />
        {/* Comunicação */}
        <Route
          path="/:accessType/comunicacao"
          element={<scenes.listScenes.Comunication />}
        />
        {/* Próximos Eventos */}
        <Route
          path="/:accessType/eventos"
          element={<scenes.listScenes.NextEvents />}
        />
        {/* Comissões e Câmaras Técnicas */}
        <Route
          path="/:accessType/comissoes_e_camaras_tecnicas"
          element={<scenes.MenuLinksScenes.CommissionsAndChambers />}
        />
        {/* Multimídia */}
        <Route
          path="/:accessType/multimidia"
          element={<scenes.cardDisplayScenes.Multimedia />}
        />
        {/* Ouvidoria */}
        <Route
          path="/:accessType/ouvidoria"
          // element={<scenes.formScenes.Ombudsman />}
          element={<Navigate to="/home_profissional" />}
        />
        {/* Denúncias */}
        <Route
          path="/:accessType/fiscalizacao/denuncias/formulario_denuncia"
          element={<scenes.formScenes.Reports />}
        />
        {/* Manuais e Orientações */}
        <Route
          exact
          path="/:accessType/manuais_e_orientacoes"
          element={<scenes.listScenes.ManualsAndOrientations />}
        />
        <Route
          path="/:accessType/manuais_e_orientações/:manualOrOrientationID"
          element={<scenes.MenuLinksScenes.ManualsAndOrientationsScene />}
        />
        {/* Clube de Vantagens */}
        <Route
          exact
          path="/:accessType/clube_de_vantagens"
          element={<scenes.PerksClub />}
        />
        {/* Podcast */}
        <Route path="/:accessType/podcast" element={<scenes.Podcast />} />
        {/* 2ª Via de Anuidade */}
        <Route
          path="/:accessType/anuidade"
          element={<scenes.listScenes.Annuity />}
        />
        {/* Informações e Notícias sobre o Crefito-2 */}
        <Route
          path="/:accessType/informacoes_e_noticias"
          element={<scenes.MenuLinksScenes.Informations />}
        />
        {/* Legistation (Transparency) */}
        <Route
          exact
          path="/:accessType/transparencia/acordaos_coffito"
          element={<scenes.listScenes.legislationScenes.CoffitoAccords />}
        />
        <Route
          path="/:accessType/transparencia/acordaos_coffito/:coffitoAccordID"
          element={
            <scenes.MenuLinksScenes.legislationScenes.CoffitoAccordsScene />
          }
        />
        <Route
          exact
          path="/:accessType/transparencia/leis_e_decretos"
          element={<scenes.listScenes.legislationScenes.LawsAndDecrees />}
        />
        <Route
          path="/:accessType/transparencia/leis_e_decretos/:lawOrDecreeID"
          element={
            <scenes.MenuLinksScenes.legislationScenes.LawsAndDecreesScenes />
          }
        />
        <Route
          exact
          path="/:accessType/transparencia/resolucoes_coffito"
          element={<scenes.listScenes.legislationScenes.CoffitoResolutions />}
        />
        <Route
          path="/:accessType/transparencia/resolucoes_coffito/:resolutionID"
          element={
            <scenes.MenuLinksScenes.legislationScenes.CoffitoResolutionsScene />
          }
        />
        <Route
          exact
          path="/:accessType/transparencia/normativas_crefito2"
          element={<scenes.listScenes.legislationScenes.CrefitoNormatives />}
        />
        <Route
          path="/:accessType/transparencia/normativas_crefito2/:normativeID"
          element={
            <scenes.MenuLinksScenes.legislationScenes.CrefitoNormativesScene />
          }
        />
        {/* Ações Sociais */}
        <Route
          exact
          path="/:accessType/acoes_sociais"
          element={<scenes.listScenes.SocialActions />}
        />
        <Route
          path="/:accessType/acoes_sociais/:socialActionID"
          element={<scenes.MenuLinksScenes.SocialActionsScene />}
        />
        {/* Eleições */}
        <Route
          exact
          path="/:accessType/eleicoes"
          element={<scenes.listScenes.Elections />}
        />
        <Route
          path="/:accessType/eleicoes/:electionID"
          element={<scenes.MenuLinksScenes.ElectionsScene />}
        />
        {/* Fale Conosco */}
        <Route
          path="/:accessType/fale_conosco"
          element={<scenes.formScenes.ContactUs />}
        />
        {/* Formulário REFIS */}
        <Route
          path="/:accessType/adesao_refis"
          // element={<scenes.formScenes.Refis}
          element={<Navigate to="/home_profissional" />}
        />
        {/* Formulários de Desconto de Anuidade */}
        <Route
          path="/:accessType/desconto_anuidade/resolucao_435"
          element={<scenes.formScenes.AnnuityDiscountsForms.Resolution435 />}
        />
        <Route
          path="/:accessType/desconto_anuidade/resolucao_472"
          element={<scenes.formScenes.AnnuityDiscountsForms.Resolution472 />}
        />
        <Route
          path="/:accessType/desconto_anuidade/resolucao_538"
          element={<scenes.formScenes.AnnuityDiscountsForms.Resolution538 />}
        />
        {/* Memorial */}
        <Route
          path="/:accessType/memorial"
          element={<scenes.listScenes.Memorial />}
        />
        {/* Homenagens */}
        <Route
          path="/:accessType/homenagens"
          element={<scenes.listScenes.Tributes />}
        />
        {/* Busca */}
        <Route
          path="/:accessType/buscar"
          element={<scenes.listScenes.SearchResults />}
        />
        {/* Documentos Digitais */}
        <Route
          path="/:accessType/documentos_digitais"
          element={<scenes.listScenes.DigitalDocuments />}
        />
        {/* dermatofuncional */}
        <Route
          path="/:accessType/documentos_digitais/dermatofuncional"
          element={<scenes.listScenes.Dermatofunctional />}
        />
        {/* Fiscalização */}
        <Route
          exact
          path="/:accessType/fiscalizacao"
          element={<scenes.listScenes.Fiscalization />}
        />
        {/* Matéria de Fiscalização */}
        <Route
          path="/:accessType/fiscalizacao/materia/:contentID"
          element={<scenes.MenuLinksScenes.FiscalizationScene />}
        />
        {/* Denúncias */}
        <Route
          exact
          path="/:accessType/fiscalizacao/denuncias"
          element={<scenes.listScenes.ReportTypes />}
        />
        {/* Denúncia Identificada */}
        <Route
          path="/:accessType/fiscalizacao/denuncias/denuncias_identificadas"
          element={<scenes.formScenes.IdentifiedReports />}
        />
        {/* Denúncia Anônima */}
        <Route
          path="/:accessType/fiscalizacao/denuncias/denuncias_anonimas"
          element={<scenes.formScenes.AnonymousReports />}
        />{" "}
        {/* Sedes e Subsedes */}
        <Route
          path="/:accessType/sedes_e_subsedes"
          element={<scenes.MenuLinksScenes.OfficesAndBranches />}
        />
        {/* Piso Salarial Nacional */}
        <Route
          path="/:accessType/piso_salarial_nacional"
          element={<scenes.formScenes.NationalSalaryFloor />}
        />
        {/* Justificativa Eleitoral */}
        <Route
          path="/:accessType/servicos-online/justificativa_eleitoral"
          element={<scenes.formScenes.ElectoralJustification />}
        />
        {/* Evidências: Custo Efetividade */}
        <Route
          path="/:accessType/evidencias-custo-efetividade"
          element={<scenes.Evidences />}
        />
        {/* Revistas */}
        <Route path="/:accessType/revistas" element={<scenes.Magazines />} />
        {/* Newsletter */}
        <Route
          path="/:accessType/newsletter"
          element={<scenes.formScenes.Newsletter />}
        />
        {/* Emissão de Carteira Digital para Profissionais Ativos */}
        <Route
          path="/:accessType/servicos-online/orientacoes-profissionais/emissao-carteira-pvc"
          element={<scenes.formScenes.ProfessionalCardEmission />}
        />
      </Routes>
    </Router>
  );
}

export default App;
